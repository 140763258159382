
@import './../../node_modules/normalize-scss/sass/normalize/_import-now.scss';
@import './template_styles.scss';
@import './self/_variables.scss';
@import './self/_mixin.scss';
@import './self/smart-grid.scss';


/* inject:imports */
@import "./src/styles/block/styles.scss";
/* endinject */


html {
  font-size: 16px;
  height: 100%;
}
body {
  font-size: 16px;
  @include font;
  min-height: 100%;
  @include display();
  position: relative;
}


