.top-menu__link {
      position: absolute;
      top: 8px;
      background-color: rgba(rgb(0, 183, 255),0.0);
      opacity: 0;
      &--1 {
            left: 749px;
            padding: 10px 5px;   
      }      
      &--2 {
            left: 835px;
            padding: 10px 5px;
      }      
      &--3 {
            left: 936px;
            padding: 10px 5px;
      }      
      &--4 {
            left: 1009px;
            padding: 10px 5px;
      }      
      &--5 {
            left: 1083px;
            padding: 10px 5px;
      }      
      &--6 {
            top: 591px;
            left: 823px;
            padding: 15px 80px;
      } 
      &--7 {
            top: 4905px;
            left: 214px;
            padding: 16px 110px;
      }      
      &--8 {
            top: 4967px;
            left: 214px;
            padding: 24px 150px;
      }      
      &--9 {
            top: 5044px;
            left: 214px;
            padding: 16px 156px;
      }      
      &--10 {
            top: 5113px;
            left: 214px;
            padding: 16px 158px;
      }      
      &--11 {
            top: 5184px;
            left: 214px;
            padding: 16px 154px;
      }        
      &--12 {
            top: 4904px;
            left: 1164px;
            padding: 16px 158px;
      }        
      &--13 {
            top: 4975px;
            left: 1164px;
            padding: 16px 135px;
      }    
      &--gg {
            top: 2536px;
            left: 459px;
            padding: 10px 64px;
      }    
      &--wesg {
            top: 2536px;
            left: 885px;
            padding: 10px 51px;
      }
      &--galaxy {
            top: 2536px;
            left: 1310px;
            padding: 10px 43px;
      }
      &--gg1 {
            top: 2176px;
            left: 459px;
            padding: 10px 64px;
      }    
      &--wesg1 {
            top: 2176px;
            left: 885px;
            padding: 10px 51px;
      }
      &--galaxy1 {
            top: 2176px;
            left: 1310px;
            padding: 10px 43px;
      }
      &--2-4 {
            top: 1230px;
            left: 1045px;
            padding: 26px 216px;
      }
      &--2-4-2 {
            top: 1440px;
            left: 216px;
            padding: 26px 236px;
      }
      &--2-4-3 {
            top: 1370px;
            left: 215px;
            padding: 26px 234px;
      }
      &--2-4-4 {
            top: 1370px;
            left: 1045px;
            padding: 26px 234px;
      }
      &--2-4-5 {
            top: 1440px;
            left: 1045px;
            padding: 26px 228px;
      }
      &--st {
            top: 3000px;
            left: 215px;
            padding: 26px 206px;
      }
      &--ne {
            top: 3000px;
            left: 730px;
            padding: 26px 180px;
      }
      &--or {
            top: 3000px;
            left: 1245px;
            padding: 26px 184px;
      }
      &--vk {
            top: auto;
            bottom: 156px;
            left: 819px;
            padding: 10px 14px;
      }
      &--twitch {
            top: auto;
            bottom: 156px;
            left: 879px;
            padding: 10px 14px;
      }
      &--instagram {
            top: auto;
            bottom: 156px;
            left: 940px;
            padding: 10px 12px;
      }
      &--twitter {
            top: auto;
            bottom: 156px;
            left: 998px;
            padding: 10px 13px;
      }
      &--facebook {
            top: auto;
            bottom: 156px;
            left: 1057px;
            padding: 10px 10px;
      }
}

#center {
      position: absolute;
      top: 2150px;
}
#bottom {
      position: absolute;
      top: 4670px;
}